var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),_c('section',{staticClass:"section section-default section-shop"},[_c('div',{staticClass:"container m-container-only-s"},[_c('div',{staticClass:"product-info"},[_c('div',{staticClass:"product-images"},[_c('div',{staticClass:"product-images-slider"},[_c('ul',[_c('li',{class:_vm.cat.code === 'alcoholic_beverages'
                    ? 'product-images-slider-logo'
                    : ''},[_c('img',{attrs:{"src":_vm.item.image,"alt":""}})])])])]),_c('div',{staticClass:"product-description"},[(_vm.$i18n.locale !== 'en')?_c('span',{staticClass:"category"},[_vm._v(_vm._s(_vm.cat.title))]):(_vm.cat.translations[_vm.$i18n.locale])?_c('span',{staticClass:"category"},[_vm._v(_vm._s(_vm.cat.translations[_vm.$i18n.locale].title))]):_c('span',{staticClass:"category"},[_vm._v(_vm._s(_vm.cat.title))]),_c('h1',[_vm._v(_vm._s(_vm.item.title))]),(_vm.item.description && _vm.item.description[this.$i18n.locale])?[_vm._v(" "+_vm._s(_vm.item.description[this.$i18n.locale])+" ")]:_vm._e(),_c('div',{staticClass:"price"},[_c('strong',[(_vm.item.price.position && _vm.item.price.position === 'start')?[_vm._v(_vm._s(_vm.item.price.currency))]:_vm._e(),_vm._v(" "+_vm._s(_vm.item.price.amount)+" "),(_vm.item.price.position && _vm.item.price.position === 'end')?[_vm._v(_vm._s(_vm.item.price.currency))]:[_vm._v("₽")]],2),(!_vm.card.find(function (it) { return it.productId === _vm.item.product_id; }))?_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.changeAmount({
                  category: _vm.cat.code,
                  productId: _vm.item.product_id,
                  action: 'ADD_TO_CARD'
                })}}}):_c('p',{staticClass:"amount"},[_c('span',{staticClass:"minus",on:{"click":function($event){$event.preventDefault();return _vm.changeAmount({
                    productId: _vm.item.product_id,
                    action: 'DECREMENT_AMOUNT_CARD'
                  })}}}),_c('b',[_vm._v(_vm._s(_vm.card.find(function (it) { return it.productId === _vm.item.product_id; }).amount))]),_c('span',{staticClass:"plus",class:{
                  disabled:
                    parseInt(_vm.item.volume) <=
                    parseInt(
                      _vm.card.find(function (it) { return it.productId === _vm.item.product_id; }).amount
                    )
                },on:{"click":function($event){$event.preventDefault();return _vm.changeAmount({
                    productId: _vm.item.product_id,
                    action: 'INCREMENT_AMOUNT_CARD'
                  })}}})])])],2)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }