<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>

    <section class="section section-default section-shop">
      <div class="container m-container-only-s">
        <div class="product-info">
          <div class="product-images">
            <div class="product-images-slider">
              <ul>
                <li
                  :class="
                    cat.code === 'alcoholic_beverages'
                      ? 'product-images-slider-logo'
                      : ''
                  "
                >
                  <img :src="item.image" alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div class="product-description">
            <span class="category" v-if="$i18n.locale !== 'en'">{{
              cat.title
            }}</span>
            <span class="category" v-else-if="cat.translations[$i18n.locale]">{{
              cat.translations[$i18n.locale].title
            }}</span>
            <span class="category" v-else>{{ cat.title }}</span>
            <h1>{{ item.title }}</h1>
            <template
              v-if="item.description && item.description[this.$i18n.locale]"
            >
              {{ item.description[this.$i18n.locale] }}
            </template>
            <div class="price">
              <strong
                ><template
                  v-if="item.price.position && item.price.position === 'start'"
                  >{{ item.price.currency }}</template
                >
                {{ item.price.amount }}
                <template
                  v-if="item.price.position && item.price.position === 'end'"
                  >{{ item.price.currency }}</template
                >
                <template v-else>₽</template></strong
              >
              <a
                href=""
                v-if="!card.find(it => it.productId === item.product_id)"
                @click.prevent="
                  changeAmount({
                    category: cat.code,
                    productId: item.product_id,
                    action: 'ADD_TO_CARD'
                  })
                "
              ></a>
              <p class="amount" v-else>
                <span
                  class="minus"
                  @click.prevent="
                    changeAmount({
                      productId: item.product_id,
                      action: 'DECREMENT_AMOUNT_CARD'
                    })
                  "
                ></span>
                <b>{{
                  card.find(it => it.productId === item.product_id).amount
                }}</b>
                <span
                  class="plus"
                  :class="{
                    disabled:
                      parseInt(item.volume) <=
                      parseInt(
                        card.find(it => it.productId === item.product_id).amount
                      )
                  }"
                  @click.prevent="
                    changeAmount({
                      productId: item.product_id,
                      action: 'INCREMENT_AMOUNT_CARD'
                    })
                  "
                ></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ShopItem",
  props: ["category", "product"],
  title() {
    return this.$t("pages.shop");
  },
  computed: {
    ...mapState("shop", {
      catalog: state => state.index,
      card: state => state.card
    }),
    cat() {
      return this.catalog[this.category];
    },
    item() {
      return this.catalog[this.category].goods.find(
        item => parseInt(item.product_id) === parseInt(this.product)
      );
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.actionIndex();
    });
  },
  methods: {
    ...mapActions("shop", {
      actionIndex: "index",
      changeAmount: "changeAmount"
    }),
    ...mapMutations("shop", {
      addToCard: "ADD_TO_CARD",
      incrementAmountCard: "INCREMENT_AMOUNT_CARD",
      decrementAmountCard: "DECREMENT_AMOUNT_CARD"
    })
  }
};
</script>

<style scoped>
.disabled {
  opacity: 0.4;
}
</style>
